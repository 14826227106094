import React, {Component} from 'react';
import './CheckoutForm.css'
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components'

const SubmitButton = styled.button`
    background-color: #37D996;
    height: 60px;
    color: white;
    font-size: 16px;
    font-family: 'Avenir';
    line-height: 60px;
    text-align: center;
    width: 100%;
    border: none;
    border-radius: 3px;

    cursor: pointer;

    :hover {
      background-color: #13C87D;
    }
`;

class FreeSampleModal extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = { name: '', email: '', loading: false }
  }

  async submit(ev) {
    window.ga('send', {
        hitType: 'event',
        eventCategory: 'Purchase',
        eventAction: 'click',
        eventLabel: 'Free sample submit button clicked'
    })
    if (this.state.loading === true) return
    if (this.state.email === '') {
      alert('The email field cannot be empty')
      return
    }
    
    this.setState({ loading: true })

    let response = await fetch("/sample", {
        method: "POST",
        headers: {"Content-Type": "text/plain"},
                    body: JSON.stringify({ name: this.state.name, 
                                        email: this.state.email})
                });
    
    if (response.ok) {
        this.props.onEmailSubmitComplete()
        this.setState({ loading: false })
    } else {
        this.setState({ loading: false })
    }
  }

  onNameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  onEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    const props = this.props

    return (
        <Modal show={props.show} onHide={props.handleClose} backdropClassName='crux-modal-backdrop'>
          <Modal.Header closeButton>
            <Modal.Title>Get A Free Sample Of <span style={{ fontWeight: 'bold' }}>The Crux</span></Modal.Title>
          </Modal.Header>
          <Modal.Body className="checkout-modal-body">
            <div className="form-group">
                <input type="text" 
                        className="form-control" 
                        id="name" 
                        aria-describedby="nameHelp" 
                        placeholder="Name"
                        onChange={this.onNameChange}
                        value={this.state.name}/>
                <input type="email" 
                        className="form-control" 
                        id="email" 
                        aria-describedby="emailHelp" 
                        placeholder="Email (where The Crux will be sent)"
                        onChange={this.onEmailChange}
                        value={this.state.email}/>

                <SubmitButton onClick={this.submit}>
                    { this.state.loading ? <div className="loader">Loading</div> : 'Submit' }
                </SubmitButton>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: 'left' }}>
            <div style={{ 
              fontSize: '11px',
              justifyContent: 'left'
              }}> <a className='terms-privacy-link' href='https://thecrux.rockentry.com/terms' target='_blank'>Terms and Conditions</a> | <a className='terms-privacy-link' href='https://thecrux.rockentry.com/privacy' target='_blank'>Privacy Policy</a></div>
          </Modal.Footer>
        </Modal>
    );
  }
}

export default FreeSampleModal;