import React, {Component} from 'react';
import {CardElement, injectStripe, PaymentRequestButtonElement} from 'react-stripe-elements';
import './CheckoutForm.css'
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components'

const CardElementWrapper = styled.div`
    padding: 15px;
    margin-bottom: 25px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    height: 52px;
`;

const PayButton = styled.button`
    background-color: #37D996;
    height: 60px;
    color: white;
    font-size: 16px;
    font-family: 'Avenir';
    line-height: 60px;
    text-align: center;
    width: 100%;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    :hover {
      background-color: #13C87D;
    }
`;

const CreditCardRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    line-height: 30px;
`;

const CreditCardIconWrapper = styled.div`
    display: flex;
`;

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  
    const paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'The Crux Preorder',
        amount: this.props.amount * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true
    });

    paymentRequest.on('token', ({complete, token, ...data}) => {
      complete('success');

      this.callServerWithToken(token, data.payerName, data.payerEmail)
    });

    paymentRequest.canMakePayment().then((result) => {
      this.setState({canMakePayment: !!result});
    });

    this.state = {
      canMakePayment: false,
      paymentRequest,
      name: '', 
      email: '', 
      loading: false
    };
  }

  async submit(ev) {
    window.ga('send', {
        hitType: 'event',
        eventCategory: 'Purchase',
        eventAction: 'click',
        eventLabel: 'Traditional pay button clicked'
    })
    if (this.state.loading === true) return
    if (this.state.email === '') {
        alert('The email field cannot be empty')
        return
    }
    if (!this.validateEmail(this.state.email)) {
        alert('The email is invalid.')
        return
    }

    this.setState({ loading: true })
    let {error, token} = await this.props.stripe.createToken({name: this.state.name});
    if (error) {
        console.log('error')
    } else {
      this.callServerWithToken(token, this.state.name, this.state.email)
    }
  }

  async callServerWithToken(token, name, email) {
    let response = await fetch("/charge", {
      method: "POST",
      headers: {"Content-Type": "text/plain"},
                  body: JSON.stringify({ token: token.id, 
                                          name: name || this.state.name, 
                                          email: email || this.state.email, 
                                          amount: this.props.amount })
              });
   
    if (response.ok) {
        this.props.onPurhcaseComplete()
        this.setState({ loading: false })
    } else {
        alert('An error occurred, you have not been charged.')
        this.setState({ loading: false })
    }
  }

  onNameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  onEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  render() {
    const props = this.props
    return (
        <Modal show={props.show} onHide={props.handleClose} backdropClassName='crux-modal-backdrop'>
          <Modal.Header closeButton>
            <Modal.Title className="pre-order-modal-title">Buy <span style={{ fontWeight: 'bold' }}>The Crux</span> for ${this.props.amount} </Modal.Title>
          </Modal.Header>
          <Modal.Body className="checkout-modal-body">
            <div className={this.state.canMakePayment ? 'payment-make-section' : ''}>
              <CreditCardRowWrapper className="credit-card-row-wrapper">
                  <div className="credit-or-debit">
                      Credit or Debit Card
                  </div>
                  <CreditCardIconWrapper>
                      <div className="visa-icon"></div>
                      <div className="mastercard-icon"></div>
                      <div className="amex-icon"></div>
                      <div className="discover-icon"></div>
                  </CreditCardIconWrapper>
              </CreditCardRowWrapper>
              <div className="form-group">
                  <input type="text" 
                          className="form-control" 
                          id="name" 
                          aria-describedby="nameHelp" 
                          placeholder="Name"
                          onChange={this.onNameChange}
                          value={this.state.name}/>
                  <input type="email" 
                          className="form-control" 
                          id="email" 
                          aria-describedby="emailHelp" 
                          placeholder="Email (where The Crux will be sent)"
                          onChange={this.onEmailChange}
                          value={this.state.email}/>

                  <CardElementWrapper>
                    <CardElement style={{ base: {
                                  color: '#49505A',
                                  fontFamily: 'Avenir',
                                  fontSize: '16px',
                                  fontWeight: 100,
                                  fontSmoothing: 'antialiased'
                              } 
                      }} />
                  </CardElementWrapper>

                  <PayButton onClick={this.submit}>
                    { this.state.loading ? <div className="loader">Loading</div> : <PayCTA amount={this.props.amount}/>}
                  </PayButton>
                </div>
            </div>

                { this.state.canMakePayment ? 
                  <div style={{ textAlign: 'center', marginTop: '15px'}}>
                    <p>or</p>
                    <PaymentRequestButtonElement
                      paymentRequest={this.state.paymentRequest}
                      className="PaymentRequestButton"
                      onClick={() => {
                        window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'Purchase',
                            eventAction: 'click',
                            eventLabel: 'Mobile pay button clicked'
                        })
                        const paymentRequest = this.state.paymentRequest
                        paymentRequest.update(
                          { total: {
                            label: 'The Crux Preorder',
                            amount: props.amount * 100,
                            }
                          }
                        )
                      }}
                      style={{
                        // For more details on how to style the Payment Request Button, see:
                        // https://stripe.com/docs/elements/payment-request-button#styling-the-element
                        paymentRequestButton: {
                          theme: 'light',
                          height: '64px',
                        }
                      }} />
                  </div>
                  : null
                }
            
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: 'left' }}>
            <div style={{ 
              fontSize: '11px',
              justifyContent: 'left'
              }}> <a className='terms-privacy-link' href='https://thecrux.rockentry.com/terms' target='_blank'>Terms and Conditions</a> | <a className='terms-privacy-link' href='https://thecrux.rockentry.com/privacy' target='_blank'>Privacy Policy</a></div>
          </Modal.Footer>
        </Modal>
    );
  }
}

const PayCTA = (props) => (
    <div>Pay ${props.amount}</div>
)

export default injectStripe(CheckoutForm);