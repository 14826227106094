import React, { Component } from 'react';
import './App.css';
import styled from 'styled-components'
import NumbersButton from './NumbersButton'
import CheckoutForm from './CheckoutForm'
import {Elements, StripeProvider} from 'react-stripe-elements';
import ThankYouModal from './ThankYouModal'
import FreeSampleModal from './FreeSampleModal'
import * as Constants from './Constants'

const PaymentDiv = styled.div`
  background-color: black;
  height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: 900;
  font-size: 10px;
  padding-left: 35px;
  padding-right: 35px;
`;

const SupportDiv = styled.div`
  background-color: #37D996;
  width: 100%;
  height: 23vh;
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 0;
  cursor: pointer;
`;

const PaymentHeaderCTA = styled.div`
  font-size: 38px;
  font-family: 'Avenir';
  line-height: 0.6;
  margin-top: -55px;
`;

const FreeChapter = styled.p`
  font-size: 15px;
  margin-top: -8px;
  white-space: nowrap;
`;


const ConvincedDiv = styled.div`
  font-size: 11px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
`;

const FreeSample = styled.span`
  color: #37D996;
  cursor: pointer;
  font-weight: bold;

  :hover {
    color: #13C87D;
  }
`;

const SupportText = styled.div`
  font-size: 34px;
  line-height: 23vh;
  font-family: 'Avenir';
  margin-left: 35px;  
  font-weight: 400;
`;

const SupportTextMobile = styled.div`
  font-size: 34px;
  line-height: 23vh;
  font-family: 'Avenir';
  font-weight: 400;
`;

const SupportBold = styled.span`
  font-weight: 500;
`;

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 75vh;
  justify-content: space-evenly;
  position: relative;
`;

const SupportTextArrowWrapper = styled.div`
  display: flex;
`;

const SocialWrapper = styled.div`
  display: flex;
  right: 15px;
  top: 15px;
  position: absolute;
`;

const SupportMeWrapper = styled.div`
  margin-top: -45px;
`;

class App extends Component {

  constructor(props) {
    super(props)

    this.state = { activePrice: 12 }
  }

  onNumberButtonClicked  = (amount) => {
    this.setState({ activePrice: amount, 
                    showCheckoutModal: false,
                    showThankYouModal: false,
                    showFreeSampleModal: false,
                    isPurchase: false })
  }

  getFormattedDollarAmount = (number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

    return formatter.format(number)
  }

  onSupportButtonClick = () => {
    window.ga('send', {
        hitType: 'event',
        eventCategory: 'Purchase',
        eventAction: 'click',
        eventLabel: 'Support button clicked'
    })
    const activePrice = this.state.activePrice
    if (activePrice === null || activePrice === '' ) return
    if (activePrice < 12) {
      alert('The checkout price must be at least $12.')
      return
    }
    this.setState({ showCheckoutModal: true })
  }

  onPurhcaseComplete = () => {
    this.setState({ showCheckoutModal: false, 
                    showThankYouModal: true})
  }

  onEmailSubmitComplete = () => {
    this.onCloseFreeSampleModal()
    this.setState({ showThankYouModal: true })
  }

  onCloseThankYouModal = () => {
    this.setState({ showThankYouModal: false })
  }

  onCloseFreeSampleModal = () => {
    this.setState({ showFreeSampleModal: false })
  }

  onFacebookShareClicked = () => {    
    window.FB.ui({
      method: 'share',
      href: 'https://thecrux.rockentry.com',
    }, function(response){});
  }

  componentDidMount() {
    
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : '287013581991100',
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.2'
      });
    };
  
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));

  }

  onFreeSampleClicked = () =>  {
    window.ga('send', {
        hitType: 'event',
        eventCategory: 'Purchase',
        eventAction: 'click',
        eventLabel: 'Free sample link clicked'
    })
    this.setState({ showFreeSampleModal: true })
  }

  render() {
    const mobile = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (/android/i.test(navigator.userAgent))

    return (
      <div className="App">
        <FreeSampleModal show={this.state.showFreeSampleModal} 
                          handleClose={this.onCloseFreeSampleModal}
                          onEmailSubmitComplete={this.onEmailSubmitComplete} />
        <ThankYouModal show={this.state.showThankYouModal} 
                        handleClose={this.onCloseThankYouModal}
                        onFacebookShareClicked={this.onFacebookShareClicked}/>
        <StripeProvider apiKey={Constants.STRIPE_PK}>
            <Elements>
              <CheckoutForm show={this.state.showCheckoutModal} 
                            amount={this.state.activePrice} 
                            handleClose={() => this.setState({showCheckoutModal: false })}
                            onPurhcaseComplete={this.onPurhcaseComplete}/>
            </Elements>
        </StripeProvider>
        <a onClick={() => {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'Link',
            eventAction: 'click',
            eventLabel: 'To Rockentry YouTube page from logo'
          })
        }} href='https://www.youtube.com/user/rockentry'><div className='rockentry-logo'></div></a>
        <div className="row kill-row">
          <div className="col-md-8 hero-image"></div>
          <PaymentDiv className="col-md-4">
            <SocialWrapper>
              <div className={mobile ? " messenger-icon" : "d-none"} style={{ marginRight: '15px', zIndex: 100, marginTop: '0.5px' }}><a style={{color: 'white', opacity: '0' }} href="fb-messenger://share/?link=https://thecrux.rockentry.com&app_id=287013581991100">Messenger</a></div>
              <div className="facebook-icon" onClick={() => {
                this.onFacebookShareClicked()
                window.ga('send', {
                  hitType: 'event',
                  eventCategory: 'Social',
                  eventAction: 'click',
                  eventLabel: 'Facebook share from home screen'
                })
              }}/>
              <div className="twitter-icon"><a style={{ opacity: 0 }} href="https://twitter.com/intent/tweet?text=Check%20out%20The%20Crux!%20https://thecrux.rockentry.com" onClick={() =>  {
                    window.ga('send', {
                      hitType: 'event',
                      eventCategory: 'Social',
                      eventAction: 'click',
                      eventLabel: 'Twitter share clicked from home screen'
                  })
              }} >Tweet</a></div>
            </SocialWrapper>

            <CopyWrapper>
              <PaymentHeaderCTA className="payment-header-cta">
                <p>Order</p>
                <p style={{ 'lineHeight': 1, 'whiteSpace': 'nowrap' }}>The E-Book Now</p>
                <br />
              </PaymentHeaderCTA>

              <div className="payment-header-cta-mobile">
                  <p><span className="payment-header-cta-mobile-span">Order E-Book now</span>.</p>
              </div>
              
              <SupportMeWrapper>
                <p className="support-me-cta">Support me with this project:</p>
                <NumbersButton onNumberButtonClicked={this.onNumberButtonClicked}/>
              </SupportMeWrapper>
              
              <ConvincedDiv>Not convinced? Get a <FreeSample onClick={() => this.onFreeSampleClicked()}>free sample</FreeSample></ConvincedDiv>
            </CopyWrapper>

            <SupportDiv className="support-div" onClick={() => this.onSupportButtonClick()}>
              <SupportTextArrowWrapper>
                <SupportText className="support-text">{ (this.state.activePrice ? this.getFormattedDollarAmount(this.state.activePrice) + ' ' : '')} <SupportBold>Support</SupportBold></SupportText> 
                <SupportTextMobile className="support-text-mobile">Support</SupportTextMobile>
                <div className="arrow"/>
              </SupportTextArrowWrapper>
            </SupportDiv>
          </PaymentDiv> 
        </div>
      </div>
    );
  }
}

export default App;
