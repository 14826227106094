import React from 'react';
import styled from 'styled-components'
import './NumbersButton.css'

const NumbersDiv = styled.div`
  font-size: 25px;
  font-size: 18px;
  font-weight: 800;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
`;

const NumbersButton = styled.div`
    width: 30%;
    text-align: center;
    border: 1px #444444 solid;
    border-radius: 23px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    cursor: hand;
`;

const Input  = styled.input`
  background-color: black;
  border-radius: 25px;
  border: 1px #444444 solid;
  height: 50px;
  width: 100%;
  color: white;
  padding-left: 50px;
  font-size: 20px;
  font-weight: bold;
  margin-top: -21px;
  outline:none;
`;

const DollarSign = styled.span`
  top: 20px;
  left: 20px;
  font-size: 20px;
  position: relative;
`;


class NumberButton extends React.Component {

    constructor(props) {
        super(props)

        this.state = { activePrice: 12, customDollarAmount: '' }
    }

    handleActivePrice = (price) => {
        window.ga('send', {
            hitType: 'event',
            eventCategory: 'Price',
            eventAction: 'click',
            eventLabel: price
        })

        this.setState({ activePrice: price, customDollarAmount: '' })
        this.props.onNumberButtonClicked(price)
    }

    handleCustomPriceClick = () =>  {
        window.ga('send', {
            hitType: 'event',
            eventCategory: 'Price',
            eventAction: 'click',
            eventLabel: 'Custom amount field focused'
        })
        this.handleActivePrice(null)
    }

    updateValue = (e) => {
        const re = /^[0-9\b]+$/;

        let startsWithZero = false
        if (e.target.value.length > 0) {
            const stringNumber = e.target.value.toString()
            startsWithZero = stringNumber[0] === '0'
        }

        if (e.target.value === '' || (re.test(e.target.value) && e.target.value.length < 6 && !startsWithZero)) {
            this.setState({ customDollarAmount: e.target.value })
            this.props.onNumberButtonClicked(Number.parseInt(e.target.value))
        }   
    }

    getFormattedDollarAmount = (number) => {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0
        })

        if (number === 0 || number === '') return number
    
        return formatter.format(number)
    }

    render() {
        return (
            <div className="numbers-button-wrapper">
                <NumbersDiv>
                    <NumbersButton className={(this.state.activePrice === 12 ? 'numbers-button-active' : '' )} 
                                    onClick={() => this.handleActivePrice(12)}>$12</NumbersButton>
                    <NumbersButton className={(this.state.activePrice === 20 ? 'numbers-button-active' : '' )} 
                                    onClick={() => this.handleActivePrice(20)}>$20</NumbersButton>
                    <NumbersButton className={(this.state.activePrice === 50 ? 'numbers-button-active' : '' )} 
                                    onClick={() => this.handleActivePrice(50)}>$50</NumbersButton>
                </NumbersDiv>
                <div>
                    <DollarSign className="">$</DollarSign>
                    <Input type="number" class="form-control" id="customAmount" 
                            aria-describedby="customAmount" 
                            onClick={() => this.handleCustomPriceClick()}
                            onChange={this.updateValue} 
                            value={this.state.customDollarAmount}
                            placeholder='Custom Amount'/>
                </div>
            </div>
        )
    }
}

export default NumberButton