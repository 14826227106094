import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components'
import './ThankYouModal.css'

const Header = styled.div`
    font-weight: bold;
`;

const BottomShareFooter = styled.div`
  height: 75px;
  width: 100%;
  background-color: #37D996;
  line-height: 75px;
  color: white;
  font-family: Avenir;
  font-size: 22px;
`;

const mobile = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (/android/i.test(navigator.userAgent))

const ThankYouModal = (props) => (  
    <Modal show={props.show} onHide={props.handleClose} 
            backdropClassName='crux-modal-backdrop'>
          <Modal.Header closeButton>
            <Header>Thank You!</Header>
          </Modal.Header>
          <Modal.Body className="thank-you-modal-body">
            <p>
                An email with a link to the PDF is on its way and should arrive in a few minutes! If you don't see it in your inbox, please check your Spam folder.
            </p>
            <p> If you have any questions,
                please send an email to <a href="mailto: oswaldo@rockentry.com">oswaldo@rockentry.com</a>.
            </p>
          </Modal.Body>
          <Modal.Footer className='thank-you-modal-footer'>
            <BottomShareFooter>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <div style={{ paddingRight: '30px' }}>Let your friends know :</div>
                <div className={mobile ? " messenger-icon-thank-you" : "d-none"}><a style={{color: 'white', opacity: '0' }} href="fb-messenger://share/?link=https://thecrux.rockentry.com&app_id=287013581991100" onClick={() => {
                    window.ga('send', {
                      hitType: 'event',
                      eventCategory: 'Social',
                      eventAction: 'click',
                      eventLabel: 'Messenger button clicked from thank you'
                    })
                }}>Messenger</a></div>
                <div className="facebook-icon-thank-you" onClick={() => {
                  window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'Social',
                    eventAction: 'click',
                    eventLabel: 'Facebook share from thank you'
                  })
                  props.onFacebookShareClicked()
                }}/>
                <div className="twitter-icon-thank-you"><a style={{ opacity: 0 }} href="https://twitter.com/intent/tweet?text=Check%20out%20The%20Crux!%20https://thecrux.rockentry.com" onClick={() => {
                  window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'Social',
                    eventAction: 'click',
                    eventLabel: 'Twitter share from thank you'
                  })
                }}>Tweet</a></div>
              </div>
              
            </BottomShareFooter>
          </Modal.Footer>
        </Modal>
)

export default ThankYouModal;